@import "@/assets/scss/style.scss";

.c-reset-password {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    &__logo {
        margin-bottom: 40px;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        align-items: center;
    }

    &__form {
        width: 100%;
    }

    &__form-fields {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 32px;
        border-radius: 18px;
        margin-bottom: 32px;
    }

    &__form-row {
        width: 100%;
    }
    &__form-text {
        font-family: 'Roboto-Regular', 'Arial', sans-serif;
        width: 64px;
        height: 20px;
        /* Regular/14px | 20px */
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        font-style: normal;
        /* identical to box height, or 143% */
        text-align: right;
        /* Grey / grey-8 */
        color: #7e7d7d;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-top: 10px;
    }
    &__button {
        width: 85%;
        border-radius: 2px;
        align-items: center;
        height: 40px;
        margin-left: 7%;
    }

    &__message {
        margin-bottom: 16px;
        color: $black;
    }

    &__loader {
        position: absolute;
        right: 10px;
    }

    &__additional-info {
        margin-bottom: 24px;
        max-width: 380px;
    }
}

.co-login {
    // display: flex;
    &__main {
        display: flex;
        flex-wrap: nowrap;
    }

    &__left {
        width: 50%;
    }

    &__right {
        width: 50%;
        background: #386ec2;
    }
    &__logo {
        margin-top: 40px;
        margin-left: 40px;
    }

    &__footer {
        position: fixed;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-left: 40px;
        margin-bottom: 40px;
        color: #8c8c8c;
        left: 0;
        bottom: 0;
    }

    &__message {
        margin-bottom: 16px;
        color: $black;
    }

    &__additional-info {
        margin-bottom: 24px;
        text-align: center;
        max-width: 380px;
    }

    @media (orientation: portrait) {
        &__right {
            display: none;
        }
        &__left {
            width: 100%;
        }
    }

    &__additional-info {
        margin-bottom: 24px;
        text-align: center;
        max-width: 380px;
    }
}
